import BatteryFinder from "./components/findYourBattery/results";
import FindARetailer from "./components/retailFinder/findARetailer";
import FindYourBattery from "./components/findYourBattery/form";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { BrowserRouter as Router } from "react-router-dom";
import Svgs from "./components/svgs";
import WhereToBuy from "./components/whereToBuy/whereToBuy";

const batteryFinder = document.getElementById("batteryFinder");
const findYourBattery = document.getElementById("findYourBattery");
const findARetailer = document.getElementById("findARetailer");
const whereToBuy = document.getElementById("whereToBuy");

const batteryFinderPage = document.getElementById(
  "batteryFinderPage"
) as HTMLInputElement;
const retailFinderPage = document.getElementById(
  "retailFinderPage"
) as HTMLInputElement;

function App() {
  return (
    <Router>
      <QueryParamProvider
        options={{ enableBatching: true }}
        adapter={ReactRouter6Adapter}
      >
        <Svgs />
        {findARetailer && <FindARetailer portal={true} />}
        {findYourBattery && (
          <FindYourBattery
            type="primary"
            portal={true}
            onFinish={(zip, ymme, questionnaire) => {
              window.location.href = `${batteryFinderPage?.value}?zipCode=${zip}&radius=15&vehType=${ymme.vehicleType}&y=${ymme.year}&mk=${ymme.make}&md=${ymme.model}&eng=${ymme.engine}`;
            }}
          />
        )}
        {whereToBuy && (
          <WhereToBuy
            onFinish={(zip, radius, country) => {
              window.location.href = `${retailFinderPage?.value}?zipCode=${zip}&radius=${radius}&country=${country}`;
            }}
          />
        )}
        {batteryFinder && <BatteryFinder />}
      </QueryParamProvider>
    </Router>
  );
}

export default App;
