import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

interface CheckboxItem {
  label: string,
  register: string,
  info?: string,
}
export default function QuestionnaireStep2({
  finish,
  back,
  close,
}: {
  finish: (form: FieldValues) => void;
  back: () => void;
  close: () => void;
}) {
  const {
    register,
    reset,
    formState: { errors, isValid, isDirty },
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  const [activeInfo, setActiveInfo] = useState<string[]>([]);

  const inputValues: CheckboxItem[] = [
    {
      label: "Start-Stop Technology",
      register: "startStop",
      info: "A start-stop system or start-stop technology automatically shuts down and restarts the internal combustion engine to reduce the amount of time the engine spends idling, thereby reducing fuel consumption and emissions"
    },
    {
      label: "Premium Car Audio",
      register: "premiumAudio",
      info: ""
    },
    {
      label: "Premium Package (Power Seats, Sunroof, Liftgate, etc.)",
      register: "premiumPackage",
      info: ""
    },
    {
      label: "Infotainment System",
      register: "infotainment",
      info: ""
    },
    {
      label: "Additional Plug-in Electronic Devices",
      register: "electronicPlugins",
      info: ""
    },
    {
      label: "Snow Plow",
      register: "snowPlow",
      info: ""
    },
    {
      label: "Winch",
      register: "winch",
      info: ""
    },
    {
      label: "E Turbo/E Supercharger",
      register: "supercharger",
      info: "An E Turbo is an ultra-high speed electric motor that works in conjunction with a conventional turbocharger. An E Supercharger is a specific type of supercharger for internal combustion engines that uses electrically powered forced-air system containing an electric motor"
    }
  ]

  const showInfoMessage = (info: string) => {
    if (activeInfo.indexOf(info) > -1) {
      setActiveInfo(activeInfo.filter((item) => item !== info));
    } else {
      setActiveInfo((activeInfo) => [...activeInfo, info]);
    }
  }

  const renderCheckboxItem = (item: CheckboxItem) => (
    <li className="checkbox-item" key={item.register}>
      <label className="checkbox-item__label">
        <input className="checkbox-item__input" type="checkbox" {...register(item.register)} />
        <span className="checkbox-item__text">{item.label}</span>
        {
          item.info !== "" &&
          <>
            <button type="button" className="question-form__info-button" onClick={() => showInfoMessage(item.register)}>
              <svg className="question-form__info" aria-hidden="true">
                <use href="#icon-info"></use>
              </svg>
            </button>
            <div className="question-form__info-message" hidden={!activeInfo.includes(item.register)}>{item.info}</div>
          </>
        }
      </label>
    </li>
  )

  return (
    <>
      <div className="dialog-modal">
        <div className="dialog-modal__dialog">
          <div className="dialog-modal__header">
            <h2 className="dialog-modal__heading">Find the Right Battery for Me</h2>
            <button type="button" className="dialog-modal__close" aria-label="close modal" onClick={close}>
              <svg aria-hidden="true" className="dialog-modal__icon">
                <use href="#icon-close"></use>
              </svg>
            </button>
          </div>
          <div className="dialog-modal__content">
            <form onSubmit={handleSubmit((data) => finish(data))} className="question-form">
              <div className="form-group">
                <fieldset className="form__fieldset">
                  <legend className="form__legend">In Order to provide the correct battery recommendation for your
                  vehicle, please answer a few questions about your driving style.</legend>
                  <div className="row">
                    <div className="col-md-6">
                      <ul className="list-unstyled">
                        {
                          inputValues.slice(0, inputValues.length / 2).map((item) => {
                            return renderCheckboxItem(item)
                          })
                        }
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul className="list-unstyled">
                        {
                          inputValues.slice(inputValues.length / 2, inputValues.length).map((item) => {
                            return renderCheckboxItem(item)
                          })
                        }
                      </ul>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="form-group">
                <label className="form__label" htmlFor="batteryReplaceYear">When was your last battery replaced? (Optional)</label>
                <div className="form__select-wrapper">
                  <select {...register("batteryReplaceYear", { valueAsNumber: true })} className="form-control form__select">
                    <option>Select Years</option>
                    <option value={0}>Never</option>
                    {Array.from(Array(11).keys())
                      .slice(1)
                      .map((v) => (
                        <option value={v} key={v}>
                          {v} year{v > 1 ? "s" : ""} ago
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="form-group text-center my-0">
                <button type="button" className="button button--secondary mr-4" onClick={back}>
                  Back
                </button>
                <button disabled={!isValid} className="button">Finish</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
