import { useEffect, useState } from "react";

export default function Recipients({
  share,
  closeModal,
}: {
  closeModal: () => void;
  share: (emails: any[]) => void;
}) {
  const [recipients, setRecipients] = useState([
    {
      value: '',
      valid: true,
      isEmpty: false,
    }
  ]);
  const [validForm, setValidForm] = useState(false);

  const handleInputChange = (event: any, index: number) => {
    const value = event.target.value;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValid = emailRegex.test(value);
    const isEmpty = value.length === 0;
    setRecipients(recipients.map((item,itemIndex) => itemIndex === index
      ? {...item, value: value, valid: isValid, isEmpty: isEmpty}
      : item));
  }
  const addRecipient = () => {
    setRecipients(() => [...recipients, {
      value: '',
      valid: true,
      isEmpty: false,
    }]);
  }
  const removeRecipient = (indexToRemove: number) => {
    setRecipients(() => [...recipients.filter((item,index) => index !== indexToRemove)]);
  }

  const submitForm = () => {
    share(recipients);
  };

  useEffect(() => {
    let formValid = true;
    recipients.forEach((item) => {
      if (item.value.length === 0) {
        formValid = false;
      }
      if (item.isEmpty) {
        formValid = false;
      }
      if (!item.valid) {
        formValid = false;
      }
    });
    if (formValid) {
      setValidForm(formValid);
    }
  },[recipients]);

	return (
		<>
			<dialog className="dialog-modal dialog-modal--secondary">
				<div className="dialog-modal__dialog">
					<div className="dialog-modal__header">
						<h2 className="dialog-modal__heading">Share</h2>
						<button
							type="button"
							className="dialog-modal__close"
							aria-label="close modal"
							onClick={closeModal}
						>
							<svg
								aria-hidden="true"
								className="dialog-modal__icon"
							>
								<use href="#icon-close"></use>
							</svg>
						</button>
					</div>
					<div className="dialog-modal__content">
            <form className="recipient-form">
              <div className="recipient-form__content">
                <h3 className="recipient-form__heading">Recipients</h3>
                {
                  recipients.map((item,index) => {
                    return (
                      <div className="recipient-form__group form-group" key={`recipient-${index}`}>
                        <div className="row">
                          <div className="col-10">
                            <input type="text" className="form-control recipient-form__input" placeholder="Email Address" value={item.value} required onChange={e => handleInputChange(e,index)}/>
                            {
                              item.isEmpty 
                              ?
                              <span className="recipient-form__error">Field is required.</span>
                              : !item.valid
                              ?
                              <span className="recipient-form__error">Please enter a valid email address.</span>
                              : ''
                            }
                          </div>
                          <div className="col-2">
                            {
                              index > 0 &&
                              <button type="button" className="recipient-form__button recipient-form__button--secondary" aria-label="remove email recipient" onClick={() => removeRecipient(index)}>
                                <svg
                                  aria-hidden="true"
                                  className="recipient-form__icon recipient-form__icon--secondary"
                                >
                                  <use href="#icon-times"></use>
                                </svg>
                              </button>
                            }
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
                <button type="button" className="recipient-form__button" aria-label="add new email recipient" onClick={addRecipient}>
                  <svg
                    aria-hidden="true"
                    className="recipient-form__icon"
                  >
                    <use href="#icon-plus"></use>
                  </svg>
                </button>
              </div>
              <div className="recipient-form__footer">
                  <button type="button" className="button" disabled={!validForm} onClick={submitForm}>Send Email</button>
              </div>
            </form>
          </div>
				</div>
			</dialog>
		</>
	);
}
