import { InputField, zipLengthError } from "../findYourBattery/form";
import { useCallback, useMemo, useState } from "react";

import ReactDOM from "react-dom";
import { debounce } from "lodash";
import { validateCAPostalCode } from "../../utilities/validateCanadaPostalCode";

export default function WhereToBuy({
  onFinish,
}: {
  onFinish: (zip: string, radius: number, country: string) => void;
}) {
  // Use CreatePortal to render outside of root
  // https://react.dev/reference/react-dom/createPortal#rendering-react-components-into-non-react-dom-nodes
  const container = document.getElementById("whereToBuy");
  // Can select either "Banner" or "Interior" views in the widget
  const template = container?.dataset.template;
  // Contact Page button in the "Banner" view only
  const contactPage = container?.dataset.contact;

  const [zipCode, setZipCode] = useState<InputField | undefined>(undefined);
  const [radius, setRadius] = useState<number>(15);
  const [country, setCountry] = useState<string>("US");
  const [toggleStatus, setToggleStatus] = useState<boolean>(false);

  const zipChangeHandler = (e: any) => {
    if (!e.target.value) {
      setZipCode(undefined);
    } else if (e.target.value.length < 5) {
      setZipCode({ value: undefined, error: zipLengthError });
    } else {
      setZipCode({ value: e.target.value });
    }
    const country = validateCAPostalCode(e.target.value);
    setCountry(country);
  };

  const debouncedZipChangeHandler = useMemo(
    () => debounce(zipChangeHandler, 300),
    []
  );

  const radiusChangeHandler = (e: any) => {
    setRadius(e.target.value);
  };

  const handleKeyUpEvent = (e:any) => {
    if (e.key === "Enter" && e.target.value.length >= 5) {
      onFinish(zipCode?.value!, radius, country)
    }
  };

  return (
    container &&
    ReactDOM.createPortal(
      <div className={`battery-form ${template === 'Interior' ? 'battery-form--secondary' : ''}`}>
        <div className="container battery-form__container">

          {
            template === 'Banner' ?
            <>
              <h3 className="battery-form__heading">
                <svg aria-hidden="true" className="battery-form__icon">
                  <use href="#icon-pin"></use>
                </svg>
                <button type="button" className={`battery-form__toggle ${toggleStatus ? 'active' : ''}`} aria-expanded={toggleStatus} onClick={() => setToggleStatus(!toggleStatus)}>Where to Buy and Recycle</button>
              </h3>

              <h3 className="battery-form__heading battery-form__heading--desktop">
                <svg aria-hidden="true" className="battery-form__icon">
                  <use href="#icon-pin"></use>
                </svg>
                Where to Buy and Recycle
              </h3>
            </>
            :
            <>
              <h3 className="battery-form__title">Where to Buy and Recycle</h3>
            </>
          }

          <div className={`battery-form__dropdown ${toggleStatus ? 'active' : ''}`}>
            <div className="battery-form__group">
              <label className="battery-form__label">
                <span className="visually-hidden">Zip / Postal Code</span>
                <input
                  className="battery-form__input form-control"
                  type="text"
                  placeholder="Zip / Postal Code"
                  onKeyUp={handleKeyUpEvent}
                  onChange={debouncedZipChangeHandler}
                />
                {
                  zipCode?.error && <span className="battery-form__error">{zipCode.error}</span>
                }
              </label>
            </div>
              
            <div className="battery-form__group">
              <select className="battery-form__select form-control" value={radius ?? "15"} onChange={radiusChangeHandler}>
                <option disabled>Radius</option>
                <option value="5">5 mi</option>
                <option value="10">10 mi</option>
                <option value="15">15 mi</option>
                <option value="20">20 mi</option>
                <option value="25">25 mi</option>
                <option value="100">100 mi</option>
                <option value="200">200 mi</option>
                <option value="500">500 mi</option>
              </select>
            </div>

            <button
              className="battery-form__button"
              type="button"
              disabled={!radius || !zipCode || !!zipCode.error}
              onClick={() => onFinish(zipCode?.value!, radius, country)}
            >
              Search
            </button>
          </div>

          {
            contactPage && template === 'Banner' &&
            <a href={contactPage} className="button battery-form__button-secondary desktop-only">Contact Us</a>
          }
          
        </div>
      </div>,
      container
    )
  );
}
