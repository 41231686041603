import {
  ChangeEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FieldValues, useForm } from "react-hook-form";
import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";

import GoogleMap from "./map";
import ReactDOM from "react-dom";
import { Wrapper } from "@googlemaps/react-wrapper";
import { debounce } from "lodash";
import useSWR from "swr";
import { validateCAPostalCode } from "../../utilities/validateCanadaPostalCode";

const getPartnersUrl = () =>
  `https://${(window as any).pzHost}/api/v1/getRetailers`;

interface GetPartnersPayload {
  zip: string;
  distance: number;
  country: string; // US or CANADA
}

export interface Pin {
  pos: google.maps.LatLng;
  distance: string;
  title: string;
  address: string;
  website: string;
  phone: string;
}

export enum SortOption {
  BY_NEAREST,
  BY_NAME,
}

export interface ActiveMarker {
  lat: number;
  lng: number;
}

const fetcher = (payload: GetPartnersPayload | undefined) => {
  if (!payload) {
    throw new Error("Please check your search parameters and try again");
  }
  return fetch(getPartnersUrl(), {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      apiKey: getPartnersUrl().includes("autobatteries.com")
        ? "Umdhe87avrKra_43432jsahKwPo"
        : "KKSNvn456javrKra_43432kjdkfsJ",
    },
    body: JSON.stringify(payload),
  }).then((res) => res.json() as Promise<any[]>);
};

const RadiusParam = withDefault(NumberParam, 15);
const CountryParam = withDefault(StringParam, "US");

export default function FindARetailer({
  portal,
}: {
  portal?: boolean | undefined;
}) {
  const supportsGeolocation = !!navigator.geolocation;
  const [zipCode, setZipCode] = useQueryParam("zipCode", StringParam);
  const [radius, setRadius] = useQueryParam("radius", RadiusParam);
  const [country, setCountry] = useQueryParam("country", CountryParam);
  const [activeMarker, setActiveMarker] = useState<ActiveMarker>();
  // Use CreatePortal to render outside of root
  // https://react.dev/reference/react-dom/createPortal#rendering-react-components-into-non-react-dom-nodes
  const container = document.getElementById("findARetailer");

  // We use this ref callback to update our zip code text input whenever the params zipCode state object changes
  // (we do this on load, and whenever someone clicks the "use my location" button)
  const zipCodeInputRef = useCallback(
    (node: HTMLInputElement) => {
      if (node !== null) {
        node.value = zipCode ?? "";
      }
    },
    [zipCode]
  );

  // on zip code change, need to update the country if its CA or US
  useMemo(() => {
    if (zipCode) {
      const country = validateCAPostalCode(zipCode);
      setCountry(country);
    }
  }, [zipCode]);

  const [sort, setSort] = useState("nearest");

  let searchParams: GetPartnersPayload | undefined =
    !!zipCode && !!country && !!radius
      ? {
          zip: zipCode,
          country: country,
          distance: radius,
        }
      : undefined;

  // This is our fetch/service call which will load the retailers whenever our search parameters are updated
  const {
    data: retailers,
    error,
    isLoading,
    isValidating,
  } = useSWR(["getRetailers", searchParams], (vars) => fetcher(vars[1]), {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });

  // We sort the retailers client-side because the API does not support sorting
  const sortedRetailers =
    retailers
      ?.sort((a, b) => {
        if (sort === "nearest") {
          return a.distance - b.distance;
        } else {
          return a.name.localeCompare(b.name);
        }
      })
      .map((r) => {
        return {
          pos: new window.google.maps.LatLng(r.lat, r.lgt),
          distance: `${Math.round(r.distance * 100) / 100} Miles`,
          title: r.name,
          address: `${r.addressLine1}<br/>
                    ${r.addressLine2 ? `${r.addressLine2}<br/>` : ""}
                    ${r.city} ${r.state} ${r.zip}`,
          website: r.website,
          phone: r.phone,
        } as Pin;
      }) ?? [];

  // The onClick handle for the "Use my location" button
  const useMyLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        // The PhaseZero API does not support searching by latitude and longitude, so we must pass the lat/lng to Google's
        // Geocoder API so we can get a postal code / country pair to search with
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode(
          {
            location: {
              lat: pos.coords.latitude,
              lng: pos.coords.longitude,
            },
          },
          function (res, status) {
            if (status === "OK") {
              const result = res?.filter(
                (r) =>
                  r.address_components.some((a) =>
                    a.types.includes("postal_code")
                  ) &&
                  r.address_components.some((a) => a.types.includes("country"))
              )?.[0];
              if (result) {
                const zip = result.address_components.find((a) =>
                  a.types.includes("postal_code")
                )?.short_name;
                const country = result.address_components.find((a) =>
                  a.types.includes("country")
                )?.short_name;
                if (zip && country) {
                  setZipCode(zip);
                  setCountry(country === "CA" ? "CANADA" : country);
                }
              }
            }
          }
        );
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const zipCodeChangedHandler = (e: any) => {
    //   // Update our state for search paramters (and the URL query string)
    //   // Weird quirk with the zipCode parameter... The Autobatteries.com current site just takes the value in this search input and passes it into the "zipCode"
    //   // property. It would seem that PhaseZero's API has the ability to accept an address string within the "zipCode" property. I'm guessing they are using Google's
    //   // Geocoder API from their server code there as well.
    setZipCode(e.target.value);
    const country = validateCAPostalCode(e.target.value);
    setCountry(country);
  };

  const debouncedZipCodeChangedHandler = useMemo(
    () => debounce(zipCodeChangedHandler, 300),
    []
  );

  const countryChangedHandler = (e: any) => {
    setCountry(e.target.value);
  };

  const sortChangeHandler = (e: any) => {
    setSort(e.target.value);
  };

  const radiusChangeHandler = (e: any) => {
    setRadius(e.target.value);
  };

  useEffect(() => {
    return () => {
      debouncedZipCodeChangedHandler.cancel();
    };
  }, []);

  const renderComponent = () => (
    <Wrapper apiKey={"AIzaSyBV4bCpqzc3e_2x7QeoKsHLrK_fAA8wiDc"}>
      <div className="retail-finder">
        <div className="row">
          <div className="col-md-4">
            <div className="retail-finder__rail">
              <h2 className="retail-finder__title">Find a Retailer</h2>

              <div className="retail-finder__group">
                <div className="row">
                  <div className="col-md-7">
                    <label
                      htmlFor="location-address"
                      className="retail-find__label"
                    >
                      Specify Address, City or Zip / Postal Code
                    </label>
                  </div>
                  <div className="col-md-5">
                    {supportsGeolocation && (
                      <button
                        type="button"
                        className="retail-finder__button"
                        onClick={useMyLocation}
                      >
                        Use my location
                      </button>
                    )}
                  </div>
                </div>
                <input
                  id="location-address"
                  type="text"
                  className="form-control form-control--secondary"
                  ref={zipCodeInputRef}
                  placeholder="Specify Address, City or Zip / Postal Code"
                  onChange={debouncedZipCodeChangedHandler}
                />
              </div>

              <div className="retail-finder__group">
                <label
                  className="retail-finder__label"
                  htmlFor="location-country"
                >
                  Select Country
                </label>
                <select
                  id="location-country"
                  value={country ?? "US"}
                  onChange={countryChangedHandler}
                  className="form-control form__select"
                >
                  <option disabled>Select Country</option>
                  <option value="US">US</option>
                  <option value="CANADA">CANADA</option>
                </select>
              </div>

              <div className="retail-finder__group">
                <label
                  className="retail-finder__label"
                  htmlFor="location-radius"
                >
                  Distance Covered
                </label>
                <select
                  id="location-radius"
                  value={radius ?? "15"}
                  onChange={radiusChangeHandler}
                  className="form-control form__select"
                >
                  <option disabled>Radius</option>
                  <option value="5">5 mi</option>
                  <option value="10">10 mi</option>
                  <option value="15">15 mi</option>
                  <option value="20">20 mi</option>
                  <option value="25">25 mi</option>
                  <option value="100">100 mi</option>
                  <option value="200">200 mi</option>
                  <option value="500">500 mi</option>
                </select>
              </div>

              <div className="retail-finder__group">
                <label className="retail-finder__label" htmlFor="location-sort">
                  Sort By
                </label>
                <select
                  id="location-sort"
                  value={sort}
                  onChange={sortChangeHandler}
                  className="form-control form__select"
                >
                  <option value="nearest">Nearest</option>
                  <option value="name">Name</option>
                </select>
              </div>

              <div className="retail-finder__results">
                {isLoading || isValidating ? (
                  <>
                    <p>Loading...</p>
                  </>
                ) : !!error && searchParams !== undefined ? (
                  <>
                    There has been an error with the search. Please try again,
                    and if the issue persists - please contact our support team.
                  </>
                ) : !!sortedRetailers && sortedRetailers.length > 0 ? (
                  <>
                    <ul className="retail-finder__list">
                      {sortedRetailers.map((item: any, index: number) => {
                        const address = { __html: item.address };
                        const latitude = item.pos.lat();
                        const longitude = item.pos.lng();
                        return (
                          <li
                            key={`${item.customerId}-${index}`}
                            className="retail-item"
                          >
                            <div
                              className="retail-item__group retail-item__group--button"
                              onClick={() =>
                                setActiveMarker({
                                  lat: latitude,
                                  lng: longitude,
                                })
                              }
                            >
                              <h3 className="retail-item__title">
                                {item.title}
                              </h3>
                              <span className="retail-items__info">
                                {item.distance}
                              </span>
                            </div>
                            <div
                              className="retail-item__group retail-item__group--button"
                              onClick={() =>
                                setActiveMarker({
                                  lat: latitude,
                                  lng: longitude,
                                })
                              }
                            >
                              <address
                                className="retail-item__address"
                                dangerouslySetInnerHTML={address}
                              ></address>
                            </div>
                            {item.website && (
                              <div className="retail-item__group">
                                <a
                                  href={item.website}
                                  target="_blank"
                                  className="retail-item__link"
                                >
                                  View Website
                                  <span className="visually-hidden">
                                    (opens in a new window)
                                  </span>
                                </a>
                              </div>
                            )}
                            <div className="retail-item__group">
                              <a
                                href={`tel:${item.phone}`}
                                className="retail-item__link retail-item__link--phone"
                              >
                                <svg
                                  aria-hidden="true"
                                  className="retail-item__icon"
                                >
                                  <use href="#icon-phone"></use>
                                </svg>
                                {item.phone}
                              </a>
                              <a
                                href={`https://www.google.com/maps?q=${item.pos.lat()},${item.pos.lng()}`}
                                className="retail-item__link retail-item__link--directions"
                                target="_blank"
                              >
                                <svg
                                  aria-hidden="true"
                                  className="retail-item__icon retail-item__icon--secondary"
                                >
                                  <use href="#icon-arrow-right"></use>
                                </svg>
                                Get Directions
                                <span className="visually-hidden">
                                  (opens in a new window)
                                </span>
                              </a>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ) : (
                  zipCode !== undefined && (
                    <>
                      <p className="retail-finder__no-results">
                        We are unable to find a distributor for entered zip /
                        postal code.
                      </p>
                    </>
                  )
                )}
              </div>
              {sortedRetailers && (
                <div className="retail-finder__footer">
                  {sortedRetailers.length} Results found
                </div>
              )}
            </div>
          </div>
          <div className="col-md-8">
            <GoogleMap retailers={retailers} activeMarker={activeMarker} />
          </div>
        </div>
      </div>
    </Wrapper>
  );
  return portal
    ? container && ReactDOM.createPortal(renderComponent(), container)
    : renderComponent();
}
