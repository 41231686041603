import FindYourBattery, {
    QuestionnaireStep1Fields,
    QuestionnaireStep2Fields,
    YMMEFormSelections,
} from "./form";
import {
    NumberParam,
    StringParam,
    useQueryParam,
    withDefault,
} from "use-query-params";
import { useEffect, useState } from "react";

import FindARetailer from "../retailFinder/findARetailer";
import ReactDOM from "react-dom";
import { resourceLimits } from "worker_threads";
import useSWR from "swr";
import Recipients from "./recipients";

// const recommendationsEndpoint = () =>
//   `https://${(window as any).pzHost}/api/v1/part/recommendations`;

const recommendationsEndpoint = `/api/recommendations`;
const shareEndpoint = "/restapi/share";



export default function BatteryFinder() {
    // Use CreatePortal to render outside of root
    // https://react.dev/reference/react-dom/createPortal#rendering-react-components-into-non-react-dom-nodes
    const container = document.getElementById("batteryFinder");

    const [zipCode, setZipCode] = useQueryParam("zipCode", StringParam);
    const [vehicleType, setVehicleType] = useQueryParam("vehType", StringParam);
    const [year, setYear] = useQueryParam("y", StringParam);
    const [make, setMake] = useQueryParam("mk", StringParam);
    const [model, setModel] = useQueryParam("md", StringParam);
    const [engine, setEngine] = useQueryParam("eng", StringParam);
    const [loaded, setLoaded] = useState(false);
    const [showRecipients, setShowRecipients] = useState(false);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [showFailureDialog, setShowFailureDialog] = useState(false);

    const share = async (emails: any[]) => {
        const recipients = emails.map((item) => item.value);
        const response = await fetch(shareEndpoint, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                Recipients: recipients,
                Recommendations: recommendations,
            }),
        });
        if (response.status === 200) {
            setShowRecipients(false);
            setShowSuccessDialog(true);
            setTimeout(() => {
                setShowSuccessDialog(false);
            }, 5000);
        }
        else {
            setShowRecipients(false);
            setShowFailureDialog(true);
            setTimeout(() => {
                setShowFailureDialog(false);
            }, 5000);
        }
    };

    const handleShareEvent = () => {
        setShowRecipients(true);
    }

    const print = () => {
        document.body.classList.add('battery-print');
        window.addEventListener('afterprint', () => {
            document.body.classList.remove('battery-print');
        });
        window.print();
    };

    const [questionnaire, setQuestionnaire] = useState<
        | (QuestionnaireStep1Fields &
            QuestionnaireStep2Fields & { changeYear?: number | null | undefined })
        | null
    >();

    useEffect(() => {
        const ymmmeData = localStorage.getItem("cxcom_ymme_data");
        if (!!ymmmeData) {
            const data: any = localStorage.getItem("cxcom_ymme_filter");
            const ymme = JSON.parse(ymmmeData);
            if (ymme.vehType === "automotive") {
                const parsed = JSON.parse(data);
                if (!!parsed) {
                    setQuestionnaire(
                        parsed.qns as QuestionnaireStep1Fields &
                        QuestionnaireStep2Fields & {
                            changeYear?: number | null | undefined;
                        }
                    );
                }
            }
        }
        setLoaded(true);
    }, []);

    const fetcher = async (
        payload:
            | (YMMEFormSelections &
                QuestionnaireStep1Fields &
                QuestionnaireStep2Fields & { changeYear?: number | null | undefined })
            | any
    ) => {
        // const { batteryReplaceYear, ...rest } = { ...payload };
        if (!payload) {
            throw new Error("Please check your ymme parameters and try again");
        }
        let filters = "nil";
        if (payload.vehicleType === "automotive")
            filters = encodeURIComponent(`&filterStr1=${encodeURIComponent(payload.averageCommuteMinutes)}&filterStr2=${encodeURIComponent(payload.averageCommuteMiles)}&filterStr3=${payload.primarilyStopAndGo}&filterStr4=${encodeURIComponent(payload.dailyStops)}&filterStr5=${payload.longPeriodsNoDriving}&filterStr6=${payload.startStop}&filterStr7=${payload.premiumAudio}&filterStr8=${payload.premiumPackage}&filterStr9=${payload.infotainment}&filterStr10=${payload.electronicPlugins}&filterStr11=${payload.snowPlow}&filterStr12=${payload.winch}&filterStr13=${payload.supercharger}&filterStr14=${payload.changeYear}`);
        // const res = await fetch(recommendationsEndpoint(), {
        //   method: "post",
        //   headers: {
        //     "Content-Type": "application/json",
        //     apiKey: recommendationsEndpoint().includes("autobatteries.com")
        //       ? "Umdhe87avrKra_43432jsahKwPo"
        //       : "KKSNvn456javrKra_43432kjdkfsJ",
        //   },
        //   body: JSON.stringify({ ...rest }),
        // });
        const res = await fetch(`${recommendationsEndpoint}?engine=${payload.engine}&filters=${filters}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
        var data = await res.json();
        if (data.results.length === 1 && Object.keys(data.results[0]).length === 0) data.results = [];
        return data;
    };

    const {
        data: recommendations,
        error,
        isLoading,
        isValidating,
    } = useSWR(
        zipCode && year && make && model && engine && loaded
            ? [
                "getRecommendations",
                {
                    zip: zipCode,
                    vehicleType,
                    year,
                    make,
                    model,
                    engine,
                    ...(questionnaire ?? {}),
                },
            ]
            : null,
        (vars) => fetcher(vars[1]),
        {
            revalidateOnFocus: false,
            shouldRetryOnError: false,
        }
    );

    return (
        container &&
        ReactDOM.createPortal(
            <>
                <div className="battery-buttons">
                    <div className="container">
                        <div className="battery-buttons__wrapper">
                            <button type="button" className="battery-buttons__button" onClick={print}>
                                <svg aria-hidden="true" className="battery-buttons__icon">
                                    <use href="#icon-print"></use>
                                </svg>
                                Print
                            </button>
                            <button type="button" className="battery-buttons__button" onClick={handleShareEvent}>
                                <svg aria-hidden="true" className="battery-buttons__icon">
                                    <use href="#icon-share"></use>
                                </svg>
                                Share
                            </button>
                        </div>
                    </div>
                </div>
                {
                    showRecipients &&
                    <Recipients share={share} closeModal={() => setShowRecipients(false)}></Recipients>
                }
                {
                    showSuccessDialog &&
                    <div className="email-success">
                        <span className="email-success__icon">
                        </span>
                        <p className="email-success__message">Email Sent Successfully</p>
                        <button type="button" className="email-success__close" aria-label="close dialog" onClick={() => setShowSuccessDialog(false)}></button>
                    </div>
                }
                {
                    showFailureDialog &&
                    <div className="email-success">
                        <span className="email-failure__icon">
                        </span>
                        <p className="email-success__message">No Recommendation to Share</p>
                        <button type="button" className="email-success__close" aria-label="close dialog" onClick={() => setShowFailureDialog(false)}></button>
                    </div>
                }
                <FindYourBattery
                    type="secondary"
                    parentZipCode={zipCode}
                    onFinish={(zip, ymme, questionnaire) => {
                        setQuestionnaire(questionnaire);
                        setZipCode(zip);
                        setVehicleType(ymme.vehicleType);
                        setYear(ymme.year);
                        setMake(ymme.make);
                        setModel(ymme.model);
                        setEngine(ymme.engine);
                    }}
                ></FindYourBattery>
                {isLoading || isValidating ? (
                    <>
                        <div className="container container--padded">
                            <p>Loading...</p>
                        </div>
                    </>
                ) : !!error && recommendations !== undefined ? (
                    <>
                        <div className="container container--padded">
                            <p>
                                There has been an error with the search. Please try again, and
                                if the issue persists - please contact our support team.
                            </p>
                        </div>
                    </>
                ) : (
                    (!!recommendations?.results &&
                        recommendations.results.length > 0) ? (
                        <div className="container">
                            <ul className="battery-results">
                                {recommendations.results.map((item: any, index: number) => (
                                    <li className="battery-card" key={`battery-card-${index}`}>
                                        {item.attributeStr3 &&
                                            <h3
                                                className={`battery-card__title ${!item.attributeStr3.toLowerCase().includes("recommended")
                                                    ? "battery-card__title--secondary"
                                                    : ""
                                                    }`}
                                            >
                                                {item.attributeStr3}
                                            </h3>
                                        }
                                        <div className="battery-card__content">
                                            <dl className="battery-card__dl">
                                                {item.technology && (
                                                    <div className="battery-card__group">
                                                        <dt className="battery-card__dt">Technology</dt>
                                                        <dd className="battery-card__dd">
                                                            {item.technology}
                                                        </dd>
                                                    </div>
                                                )}
                                                {item.groupSize && (
                                                    <div className="battery-card__group">
                                                        <dt className="battery-card__dt">Group Size</dt>
                                                        <dd className="battery-card__dd">{item.groupSize}</dd>
                                                    </div>
                                                )}

                                                {item.qualifier && (
                                                    <div className="battery-card__group">
                                                        <dt className="battery-card__dt">Qualifier</dt>
                                                        <dd className="battery-card__dd">
                                                            {item.qualifier}
                                                        </dd>
                                                    </div>
                                                )}

                                                {item.rating && (
                                                    <div className="battery-card__group">
                                                        <dt className="battery-card__dt">
                                                            Minimum Cold Cranking Amps (CCA)
                                                        </dt>
                                                        <dd className="battery-card__dd">{item.rating}</dd>
                                                    </div>
                                                )}
                                                {item.fitmentDetails && (
                                                    <div className="battery-card__group battery-card__group--secondary">
                                                        <dt className="battery-card__dt battery-card__dt--secondary">
                                                            Fitment Details
                                                        </dt>
                                                        <dd className="battery-card__dd">
                                                            <ul className="battery-card__list">
                                                                {
                                                                    item.fitmentDetails.map((fit: string, index: number) => {
                                                                        return (
                                                                            <li className="battery-card__item" key={`fitment-${index}`}>{fit.trim()}</li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </dd>
                                                    </div>
                                                )}
                                                {item.benefits?.length > 0 && (
                                                    <div className="battery-card__group battery-card__group--secondary">
                                                        <dt className="battery-card__dt battery-card__dt--secondary">
                                                            Benefits
                                                        </dt>
                                                        <dd className="battery-card__dd">
                                                            <ul className="battery-card__list">
                                                                {item.benefits.map(
                                                                    (code: string, index: number) => {
                                                                        return (
                                                                            <li
                                                                                className="battery-card__item"
                                                                                key={`reason-code-${index}`}
                                                                            >
                                                                                {code.trim()}
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        </dd>
                                                    </div>
                                                )}
                                            </dl>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) :
                        (
                            <div className="container container--padded">
                                <p className="battery-finder__no-results">Sorry, we don't have recommendations for this vehicle. Please refer to your vehicle manual.</p>
                            </div>
                        )
                )}
                <FindARetailer></FindARetailer>
            </>,
            container
        )
    );
}
