import { FieldValues, useForm } from "react-hook-form";

import { QuestionnaireStep1Fields } from "./form";
import { useState } from "react";

export default function QuestionnaireStep1({
  next,
  close,
  fields,
}: {
  next: (form: FieldValues) => void;
  close: () => void;
  fields: QuestionnaireStep1Fields | undefined;
}) {
  const {
    register,
    reset,
    formState: { errors, isValid, isDirty },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    defaultValues: fields
      ? {
          averageCommuteMinutes: fields?.averageCommuteMinutes,
          averageCommuteMiles: fields?.averageCommuteMiles,
          primarilyStopAndGo: fields?.primarilyStopAndGo ? "true" : "false",
          dailyStops: fields?.dailyStops,
          longPeriodsNoDriving: fields?.longPeriodsNoDriving ? "true" : "false",
        }
      : undefined,
  });

  const [attempted, setAttempted] = useState(!!fields);

  const inputValues = [
    {
      label: "How long is your average daily commute in minutes?",
      icon: "commute-icon",
      values: ["0-20","20-40","40-60","60+"],
      register: "averageCommuteMinutes"
    },
    {
      label: "How many miles is your average drive or commute?",
      icon: "wheel-icon",
      values: ["0-15","15-30","30-45","45+"],
      register: "averageCommuteMiles"
    },
    {
      label: "Is more than 50% of your daily driving in stop and go traffic?",
      icon: "traffic-icon",
      values: ["true","false"],
      register: "primarilyStopAndGo"
    },
    {
      label: "On a typical day, how many places do you stop at?",
      icon: "stop-icon",
      values: ["0-3","3-6","6-9","9+"],
      register: "dailyStops"
    },
    {
      label: "In the past 3 months has your vehicle been parked or not drive for 3 weeks?",
      icon: "parking-icon",
      values: ["true","false"],
      register: "longPeriodsNoDriving"
    }
  ];

  return (
    <>
      <dialog className="dialog-modal">
        <div className="dialog-modal__dialog">
          <div className="dialog-modal__header">
            <h2 className="dialog-modal__heading">Find the Right Battery for Me</h2>
            <button type="button" className="dialog-modal__close" aria-label="close modal" onClick={close}>
              <svg aria-hidden="true" className="dialog-modal__icon">
                <use href="#icon-close"></use>
              </svg>
            </button>
          </div>
          <div className="dialog-modal__content">
            <form onSubmit={handleSubmit((data) => next(data))} className="question-form">
              <p className="text-bold">In Order to provide the correct battery recommendation for your vehicle, please answer a few questions about your driving style.</p>
              {!isValid && attempted && (
                <p className="form__error">Please answer all questions before clicking Next.</p>
              )}

              {
                inputValues.map((item: any) => {
                  return (
                    <div className="form-group form-group--secondary container" key={item.register}>
                      <div className="row align-xs-center">
                        <div className="col-xs-2">
                          <img src={require(`../../images/${item.icon}.png`)} alt="" />
                        </div>
                        <div className="col-xs-10 my-1">
                          <fieldset className="form__fieldset">
                            <legend className="form__legend">{item.label}</legend>
                            <ul className="list-unstyled radio-list">
                              {
                                item.values.map((value: string, index: number) => {
                                  return (
                                    <li className="radio-item radio-item--secondary" key={index}>
                                      <label className="radio-item__label">
                                        <input
                                          type="radio"
                                          className="radio-item__input"
                                          value={value}
                                          {...register(item.register, { required: true })}
                                        />
                                        <span className="radio-item__pseudo-input"></span>
                                        {value === "true" ? "Yes" : value === "false" ? "No" : value}
                                      </label>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              <div className="form-group mb-0 text-center">
                <button
                  className="button question-form__button"
                  onClick={() => {
                    setAttempted(true);
                  }}
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </dialog>
    </>
  );
}
