import { ActiveMarker, Pin } from "./findARetailer";
import { useEffect, useMemo, useRef, useState } from "react";

export default function GoogleMap({
  retailers,
  activeMarker,
}: {
  retailers: any[] | undefined;
  activeMarker?: ActiveMarker;
}) {
  const ref = useRef(null);
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  const markersRef = useRef<google.maps.Marker[]>([]);
  //let tempMarkers: google.maps.Marker[] = [];
  //const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  const [infoWindow, setInfoWindow] = useState<
    google.maps.InfoWindow | undefined
  >(undefined);
  const markerImage = require("../../images/green-dot-sm.png");
  // Here we create the google map instance. This should only happen once
  useEffect(() => {
    setMap(
      new window.google.maps.Map(ref.current!, {
        center: { lat: 37.2756718, lng: -104.6558724 }, // US
        zoom: 5,
      })
    );

    setInfoWindow(new window.google.maps.InfoWindow());
  }, []);

  // re center the map when clicking on an item in the results list
  useMemo(() => {
    if (activeMarker) {
      map?.setCenter(activeMarker);
      map?.setZoom(15);
    }
  }, [activeMarker]);

  // Whenever our retailers list changes, we map them to our pin objects and add them to map
  useMemo(() => {
    if (!!map) {
      const pins =
        retailers?.map((r) => {
          return {
            pos: new window.google.maps.LatLng(r.lat, r.lgt),
            distance: `${Math.round(r.distance * 100) / 100} Miles`,
            title: r.name,
            address: `
              ${r.addressLine1}<br/>${
              r.addressLine2 &&
              `${r.addressLine2}<br/>
              ${r.city} ${r.state} ${r.zip}
              `
            }`,
            website: r.website,
            phone: r.phone,
          } as Pin;
        }) ?? [];

      if (!!pins && pins.length > 0) {
        // if we have active markers, we remove them from the map and remove the event listeners
        markersRef.current.forEach((m) => {
          m.setMap(null);
          window.google.maps.event.clearInstanceListeners(m);
        });

        markersRef.current = pins.map((m) => {
          const marker = new window.google.maps.Marker({
            position: m.pos,
            map: map,
            icon: markerImage,
          });

          // Bind up the click event for the markers to open our infowindow
          google.maps.event.addListener(marker, "click", () => {
            infoWindow?.close();

            infoWindow?.setContent(
              `
              <div class="info-window">
                <span class="info-window__distance">${m.distance}</span>
                <h3 class="info-window__title">${m.title}</h3>
                <address class="info-window__address">${m.address}</address>
                ${
                  m.website &&
                  `<a href="${m.website}" target="_blank" class="info-window__link">
                    Visit Website
                    <span class="visually-hidden">(opens in a new window)</span>
                  </a>`
                }
                ${
                  m.phone &&
                  `<a href="tel:${m.phone}" class="info-window__link">
                    <svg aria-hidden="true" class="info-window__icon">
                      <use href="#icon-phone"></use>
                    </svg>

                    ${m.phone}
                  </a>`
                }
                <a href="https://maps.google.com/?q=${m.pos.lat()},${m.pos.lng()}" target="_blank" class="info-window__link">
                <svg aria-hidden="true" class="info-window__icon">
                  <use href="#icon-arrow-right"></use>
                </svg>
                  Directions
                  <span class="visually-hidden">(opens in a new window)</span>
                </a>
              </div>
              `
            );

            infoWindow?.open({
              anchor: marker,
              map,
            });
          });

          return marker;
        });

        map.setCenter(pins?.[0]?.pos);
        map.setZoom(10);
      } else {
        map.setCenter({ lat: 37.2756718, lng: -104.6558724 }); // US
        map.setZoom(5);
      }
    }
  }, [retailers]);

  return (
    <>
      <div ref={ref} id="map" className="location-map" />
    </>
  );
}
