import { debounce, set } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import FormDropdown from "./formDropdown";
import QuestionnaireStep1 from "./questionnaireStep1";
import QuestionnaireStep2 from "./questionnaireStep2";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";
import useSWR from "swr";

// const ymmeApiEndpoint = () => `https://${(window as any).pzHost}/api/v1/ymme`;
const ymmeApiEndpointTypes = `/api/types`;
const ymmeApiEndpointYears = `/api/years`;
const ymmeApiEndpointMakes = `/api/makes`;
const ymmeApiEndpointModels = `/api/models`;
const ymmeApiEndpointEngines = `/api/engines`;

export interface InputField {
  value?: string | undefined | null;
  error?: string | undefined | null;
}

interface GetYMMEPayload {
  type: string;
  vehicleTypeGroup?: string | undefined | null;
  vehicleTypes?: string[] | undefined | null;
  year?: string | undefined | null;
  make?: string | undefined | null;
  model?: string | undefined | null;
}

export interface YMMEFormSelections {
  vehicleType?: string | undefined | null;
  year?: string | undefined | null;
  make?: string | undefined | null;
  model?: string | undefined | null;
  engine?: string | undefined | null;
}

export interface QuestionnaireStep1Fields {
  averageCommuteMinutes?: string | any;
  averageCommuteMiles?: string | any;
  primarilyStopAndGo?: boolean | undefined | null;
  dailyStops?: string | any;
  longPeriodsNoDriving?: boolean | undefined | null;
}

export interface QuestionnaireStep2Fields {
  startStop?: boolean | undefined | null;
  infotainment?: boolean | undefined | null;
  premiumAudio?: boolean | undefined | null;
  winch?: boolean | undefined | null;
  premiumPackage?: boolean | undefined | null;
  electronicPlugins?: boolean | undefined | null;
  snowPlow?: boolean | undefined | null;
  supercharger?: boolean | undefined | null;
  batteryReplaceYear?: string | undefined | null;
}

export const zipLengthError = "Please enter a 5-digit zip / postal code";

const fetcher = (endpoint: string) => {
  if (!endpoint) {
    throw new Error("Please check your ymme parameters and try again");
  }
  return fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    }
  }).then((res) => res.json());
};

export default function FindYourBattery({
  onFinish,
  portal,
  type,
  parentZipCode,
}: {
  onFinish: (
    zip: string,
    ymmeSelections: YMMEFormSelections,
    questionnaireFields: (QuestionnaireStep1Fields & QuestionnaireStep2Fields) | null
  ) => void;
  type: string;
  portal?: boolean | undefined;
  parentZipCode?: string | undefined | null;
}) {
  // Use CreatePortal to render outside of root
  // https://react.dev/reference/react-dom/createPortal#rendering-react-components-into-non-react-dom-nodes
  const container = document.getElementById("findYourBattery");

  const queryParams = useLocation().search;
  const zipParam = new URLSearchParams(queryParams).get("zipCode");
  const [zipCode, setZipCode] = useState<InputField | undefined>(
    queryParams
      ? {
        value: zipParam && zipParam.length < 5 ? undefined : zipParam,
        error: zipParam && zipParam.length < 5 ? zipLengthError : undefined,
      }
      : undefined
  );

  const [ymmeSelections, setYMMESelections] = useState<
    YMMEFormSelections | undefined
  >(
    queryParams
      ? {
        vehicleType: new URLSearchParams(queryParams).get("vehType"),
        year: new URLSearchParams(queryParams).get("y"),
        make: new URLSearchParams(queryParams).get("mk"),
        model: new URLSearchParams(queryParams).get("md"),
        engine: new URLSearchParams(queryParams).get("eng"),
      }
      : undefined
  );

  const [activeDropdown, setActiveDropdown] = useState<string>();

  useMemo(() => {
    if (!!parentZipCode) {
      setZipCode({
        value: parentZipCode,
        error: undefined,
      });
    }
  }, [parentZipCode]);

  const zipCodeInputRef = useCallback(
    (node: HTMLInputElement) => {
      if (node !== null) {
        node.value = parentZipCode ?? "";
      }
    },
    [parentZipCode]
  );

  const [questionnaireFields, setQuestionnaireFields] = useState<
    (QuestionnaireStep1Fields & QuestionnaireStep2Fields) | undefined
  >(undefined);
  const [questionnaireStep, setQuestionnaireStep] = useState<
    number | undefined
  >(undefined);

  const optData = (data: any[]) => {
    if (data) {
      return data.map(x => ({ label: x.val, value: x.val }))
    }
  }

  const optDataVehicleId = (data: any[]) => {
    if (data) {
      return data.map(x => ({ label: x.val, value: x.id }))
    }
  }

  const {
    data: vehicleTypes,
    error: vehicleTypesError,
    isLoading: vehicleTypesIsLoading,
    isValidating: vehicleTypesIsValidating,
  } = useSWR(
    ["getVehicleType"],
    (vars) =>
      fetcher(ymmeApiEndpointTypes).then((v) => {
        return ((v.content as any[])).map(x => {
          let newLabel = x.val === 'automotive' ? 'Auto/Truck' : 'Medium/Heavy Truck';
          return { label: newLabel, value: x.val as string };
        });
      }),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  const {
    data: years,
    error: yearsError,
    isLoading: yearsIsLoading,
    isValidating: yearsIsValidating,
  } = useSWR(
    ymmeSelections?.vehicleType ? ["getYears", ymmeSelections?.vehicleType] : null,
    (vars) =>
      fetcher(`${ymmeApiEndpointYears}?vehicle_type=${vars[1]}`).then((y) => {
        return (optData(y.content as any[]))?.sort((a, b) => {
          return +b.value - +a.value;
        });
      }),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  const {
    data: makes,
    error: makesError,
    isLoading: makesIsLoading,
    isValidating: makesIsValidating,
  } = useSWR(
    ymmeSelections?.vehicleType && ymmeSelections?.year ? ["getMakes", ymmeSelections?.vehicleType, ymmeSelections?.year] : null,
    (vars) =>
      fetcher(`${ymmeApiEndpointMakes}?vehicle_type=${vars[1]}&year=${vars[2]}`)
        .then((m) => optData(m.content as any[])),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  const {
    data: models,
    error: modelsError,
    isLoading: modelsIsLoading,
    isValidating: modelsIsValidating,
  } = useSWR(
    ymmeSelections?.vehicleType && ymmeSelections?.year && ymmeSelections?.make
      ? ["getModels", ymmeSelections?.vehicleType, ymmeSelections?.year, ymmeSelections?.make]
      : null,
    (vars) =>
      fetcher(`${ymmeApiEndpointModels}?vehicle_type=${vars[1]}&year=${vars[2]}&make=${vars[3]}`)
        .then((m) => optData(m.content as any[])),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  const {
    data: engines,
    error: enginesError,
    isLoading: enginesIsLoading,
    isValidating: enginesIsValidating,
  } = useSWR(
    ymmeSelections?.vehicleType && ymmeSelections?.year && ymmeSelections?.make && ymmeSelections?.model
      ? [
        "getEngines",
        ymmeSelections?.vehicleType,
        ymmeSelections?.year,
        ymmeSelections?.make,
        ymmeSelections?.model,
      ]
      : null,
    (vars) =>
      fetcher(`${ymmeApiEndpointEngines}?vehicle_type=${vars[1]}&year=${vars[2]}&make=${vars[3]}&model=${vars[4]}`)
        .then((m) => optDataVehicleId(m.content as any[])),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  const vehicleTypeChangeHandler = (vehicleType: string) => {
    setYMMESelections({ vehicleType: vehicleType });
  };

  const yearChangeHandler = (year: string) => {
    setYMMESelections({ vehicleType: ymmeSelections?.vehicleType, year: year });
  };

  const makeChangeHandler = (make: string) => {
    setYMMESelections({ vehicleType: ymmeSelections?.vehicleType, year: ymmeSelections?.year, make: make });
  };

  const modelChangeHandler = (model: string) => {
    setYMMESelections({
      vehicleType: ymmeSelections?.vehicleType,
      year: ymmeSelections?.year,
      make: ymmeSelections?.make,
      model: model,
    });
  };

  const engineChangeHandler = (engine: string) => {
    setYMMESelections({
      vehicleType: ymmeSelections?.vehicleType,
      year: ymmeSelections?.year,
      make: ymmeSelections?.make,
      model: ymmeSelections?.model,
      engine: engine,
    });
  };

  const zipChangeHandler = (e: any) => {
    if (!e.target.value) {
      setZipCode(undefined);
    } else if (e.target.value.length < 5) {
      setZipCode({ value: undefined, error: zipLengthError });
    } else {
      setZipCode({ value: e.target.value });
    }
  };

  const getTextFromValue: any = (list: any[], item: any) => {
    if(item && list) 
      return (((list as any[])?.filter(x => x.value === item))[0].label) as string;
  }

  const debouncedZipChangeHandler = useMemo(
    () => debounce(zipChangeHandler, 300),
    []
  );

  const handleDropdownChange = (
    dropdownStatus: boolean,
    dropdownTitle: string
  ) => {
    if (dropdownStatus) {
      setActiveDropdown(dropdownTitle);
    } else {
      setActiveDropdown("");
    }
  };

  const renderComponent = () => (
    <>
      <div
        className={`battery-finder ${type === "secondary" ? "battery-finder--secondary" : ""
          }`}
      >
        <div className="battery-finder__container container">
          {type === "primary" ? (
            <h3 className="battery-finder__title">Find Your Battery</h3>
          ) : (
            <h3 className="battery-finder__title battery-finder__title--secondary">
              Showing Results For
            </h3>
          )}
          <div className="battery-finder__group">
            <input
              className="form-control battery-finder__input"
              type="text"
              autoComplete="off"
              placeholder="Zip / Postal Code"
              onChange={debouncedZipChangeHandler}
              ref={zipCodeInputRef}
            />
            {zipCode?.error && (
              <span className="battery-finder__error">{zipCode.error}</span>
            )}
          </div>

          <div className="battery-finder__group">
            <FormDropdown
              activeDropdown={activeDropdown}
              options={vehicleTypes}
              disabled={false}
              onDropdownChange={handleDropdownChange}
              onChangeHandler={vehicleTypeChangeHandler}
              title={getTextFromValue(vehicleTypes, ymmeSelections?.vehicleType) ?? "Vehicle Type"}
              showInput={true}
              searchPlaceholder="Vehicle Type"
            ></FormDropdown>
          </div>

          <div className="battery-finder__group">
            <FormDropdown
              activeDropdown={activeDropdown}
              options={years}
              disabled={!ymmeSelections?.vehicleType}
              onDropdownChange={handleDropdownChange}
              onChangeHandler={yearChangeHandler}
              title={ymmeSelections?.year ?? "Year"}
              displayType="secondary"
            ></FormDropdown>
          </div>

          <div className="battery-finder__group">
            <FormDropdown
              activeDropdown={activeDropdown}
              options={makes}
              disabled={!ymmeSelections?.year}
              onDropdownChange={handleDropdownChange}
              onChangeHandler={makeChangeHandler}
              title={ymmeSelections?.make ?? "Make"}
              showInput={true}
              searchPlaceholder="Make"
            ></FormDropdown>
          </div>

          <div className="battery-finder__group">
            <FormDropdown
              activeDropdown={activeDropdown}
              options={models}
              disabled={!ymmeSelections?.make}
              onDropdownChange={handleDropdownChange}
              onChangeHandler={modelChangeHandler}
              title={ymmeSelections?.model ?? "Model"}
              showInput={true}
              searchPlaceholder="Model"
            ></FormDropdown>
          </div>

          <div className="battery-finder__group">
            <FormDropdown
              activeDropdown={activeDropdown}
              options={engines}
              disabled={!ymmeSelections?.model}
              onDropdownChange={handleDropdownChange}
              onChangeHandler={engineChangeHandler}
              title={getTextFromValue(engines, ymmeSelections?.engine) ?? "Engine"}
              showInput={true}
              searchPlaceholder="Engine"
            ></FormDropdown>
          </div>

          <button
            type="button"
            className="button battery-finder__button"
            onClick={() => {
              localStorage.setItem(
                "cxcom_ymme_data",
                JSON.stringify({
                  vehType: ymmeSelections?.vehicleType,
                  y: ymmeSelections?.year,
                  mk: ymmeSelections?.make,
                  md: ymmeSelections?.model,
                  eng: ymmeSelections?.engine,
                })
              )
              ymmeSelections?.vehicleType === "heavy_duty" ? (onFinish(zipCode?.value!, ymmeSelections!, null)) : setQuestionnaireStep(1);
            }}
            disabled={
              !ymmeSelections?.vehicleType ||
              !ymmeSelections?.year ||
              !ymmeSelections.make ||
              !ymmeSelections.model ||
              !ymmeSelections.engine ||
              !zipCode?.value
            }
          >
            {ymmeSelections?.vehicleType === "heavy_duty" ? "Submit" : "Next"}
          </button>
          {type === "secondary" && (
            <div className="battery-finder__results">
              <h2 className="battery-finder__heading">
                {ymmeSelections?.year &&
                  ymmeSelections?.make &&
                  ymmeSelections?.model &&
                  ymmeSelections?.engine ? (
                  <>
                    {ymmeSelections.year} {ymmeSelections.make}{" "}
                    {ymmeSelections.model} {getTextFromValue(engines, ymmeSelections?.engine)} Batteries
                  </>
                ) : (
                  "Batteries"
                )}
              </h2>
              <p className="battery-finder__summary">
                Confirm these recommendations with your local battery retailer
                to ensure proper fitment.
              </p>
            </div>
          )}
        </div>
      </div>

      {questionnaireStep === 1 && (
        <QuestionnaireStep1
          fields={questionnaireFields}
          close={() => {
            setQuestionnaireStep(undefined);
          }}
          next={(form: QuestionnaireStep1Fields) => {
            const { primarilyStopAndGo, longPeriodsNoDriving, ...rest } = {
              ...form,
            };
            setQuestionnaireFields({
              primarilyStopAndGo: primarilyStopAndGo?.toString() === "true",
              longPeriodsNoDriving: longPeriodsNoDriving?.toString() === "true",
              ...rest,
            });
            setQuestionnaireStep(2);
          }}
        />
      )}

      {questionnaireStep === 2 && (
        <QuestionnaireStep2
          close={() => {
            setQuestionnaireStep(undefined);
          }}
          back={() => {
            setQuestionnaireStep(1);
          }}
          finish={(form: QuestionnaireStep2Fields) => {
            const changeYear = form?.batteryReplaceYear
            ? new Date().getFullYear() - +form?.batteryReplaceYear
            : null;
            const {
              averageCommuteMinutes,
              averageCommuteMiles,
              primarilyStopAndGo,
              dailyStops,
              longPeriodsNoDriving,
              ...rest
            } = { ...questionnaireFields };
            const newFields = {
              ...form,
              averageCommuteMinutes,
              averageCommuteMiles,
              primarilyStopAndGo,
              dailyStops,
              longPeriodsNoDriving,
              changeYear,
            };

            localStorage.setItem(
              "cxcom_ymme_filter",
              JSON.stringify({
                qns: {...newFields},
              })
            );

            setQuestionnaireFields(undefined);
            setQuestionnaireStep(undefined);

            return onFinish(zipCode?.value!, ymmeSelections!, newFields!);
          }}
        />
      )}
    </>
  );

  return portal
    ? container && ReactDOM.createPortal(renderComponent(), container)
    : renderComponent();
}
